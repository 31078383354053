import { gql } from "graphql-request";
export const OFFER_BY_ID = gql`
    query offerById($id: Int, $language: String!, $domainId: Int) {
        offer(id: $id, isDeleted: false, isActive: true, bodyIsNull: false) {
            id
            title
            featuresListTitle
            languages
            domains(id: $domainId) {
                domainOffers(offerId: $id) {
                    offerId
                    domainId
                    placements
                }
            }
            quickOverview
            defaultAffiliateLink {
                link
            }
            description
            displayUrl
            logoId
            categories {
                id
                slug
                name
                title
                url(isActive: true, isDeleted: false) {
                    isMain
                    main {
                        url
                    }
                    url
                }
            }
            url(isActive: true, isDeleted: false) {
                isMain
                main {
                    url
                }
                url
            }
            topics(domainId: $domainId) {
                title
                image {
                    path
                    fullPath
                }
                url {
                    url
                }
                urls {
                    url
                    language
                }
            }
            imageId
            score
            name
            subTitle
            overview
            metatags {
                key
                value
            }
            translations {
                key
                value
                language
            }
            body
            faqs(languageEq: $language, isDeleted: false) {
                id
                question
                answer
            }
            workingHours {
                id
                isActive
                timeZone
                schedule
            }
            attributes(isDeleted: false) {
                value
                id
                translations {
                    key
                    value
                    language
                }
                baseAttribute {
                    weight
                    comparison
                    name
                    hidden
                    group
                    groupSlug
                    forceComparisonsDisplay
                    type
                    attributesGroup {
                        id
                        slug
                        order
                        name
                        isActive
                    }
                    translations {
                        key
                        value
                        language
                    }
                }
            }
            mainImage {
                path
                fullPath
                caption
            }
            userId
            slug
            defaultAffiliateLinkId
            features(languageEq: $language) {
                id
                type
                description
                translations {
                    key
                    value
                    language
                }
            }
            pros(languageEq: $language) {
                id
                type
                description
                translations {
                    key
                    value
                    language
                }
            }
            cons(languageEq: $language) {
                id
                type
                description
                translations {
                    key
                    value
                    language
                }
            }
            logo {
                path
                fullPath
            }
            image {
                path
                fullPath
            }
        }
    }
`;
